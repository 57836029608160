<script lang="ts" setup>
const error = useError()
</script>

<template>
  <div>
    <div class="text-center text-2xl">ขออภัย</div>
    <div v-if="error?.message.startsWith('Page not found')" class="text-center text-lg text-grey-4">
      <div class="mb-8">ไม่พบหน้านี้</div>
      <v-btn
        prepend-icon="mdi-home-outline"
        variant="outlined"
        color="primary-facebook"
        rounded="xl"
        :ripple="false"
        size="large"
        @click="navigateTo('/')"
      >
        ไปหน้าแรก
      </v-btn>
    </div>
    <div v-else class="text-center text-lg text-grey-4">ไม่พบข้อมูลทรัพย์</div>
  </div>
</template>

<style scoped></style>
